import { ReactNode } from 'react';
import classNames from 'classnames';

import Typography from '@components/atoms/Typography';

import classes from './FilterGroup.module.scss';

type Props = {
  className?: string;
  name: ReactNode;
  values: {
    key: string;
    name: string;
    count: number;
  }[];
  isSelected: (value: string) => boolean;
  onClick: (value: string) => void;
};

const FilterGroup = ({ className, name, values, isSelected, onClick }: Props): JSX.Element => (
  <div className={classNames(classes.filter, className)}>
    <Typography className={classes.name} variant="body2" renderAs="div" lineHeight="18px" weight="700">
      {name}
    </Typography>
    <div className={classes.values}>
      {values.map((value) => (
        <Typography
          key={value.key}
          variant="caption1"
          renderAs="button"
          lineHeight="18px"
          className={classNames(classes.value, { [classes.isSelected]: isSelected(value.key) })}
          onClick={() => onClick(value.key)}
        >
          {value.name}
          <span className={classes.count}>({value.count})</span>
        </Typography>
      ))}
    </div>
  </div>
);

export default FilterGroup;
