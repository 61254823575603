import { useState, useEffect } from 'react';
import { stringify } from 'querystring';

import config from 'config';
import { SortId, sortValues } from 'consts/sort';
import { Filter, FilterValue, QueryFilter } from 'types/Filter';
import Url from 'types/Url';
import SearchItem from 'types/SearchItem';
import SortValues from 'types/SortValues';
import ProductDisplayMode from 'types/ProductDisplayMode';
import { searchWithFiltersRoute } from 'apiRoutes/search';
import getSortedQueryParams from 'utils/getSortedQueryParams';
import keysOf from 'utils/keysOf';
import map from 'utils/map';
import omit from 'utils/omit';
import useBoolean from 'hooks/useBoolean';
import useFetch from 'hooks/useFetch';
import useList from 'hooks/useList';

import VariantsListView from './VariantsList';

const { routes, category } = config;

type Props = {
  initializeKey?: number;
  className?: string;
  productName?: string;
  constantFilters: QueryFilter[];
  filters: Filter[];
  variants: SearchItem[];
  sortValues?: Partial<SortValues>;
  hits: number;
  page?: number;
  displayMode?: ProductDisplayMode;
  showAllFilters?: boolean;
  withFakeLink?: boolean;
  compactWithAttributes?: boolean;
  showAll?: boolean;
  withoutAvailabilitySwitchers?: boolean;
  getPageChangeHref?: (page: number | undefined) => Url;
};

const VariantsList = ({
  className,
  productName,
  constantFilters,
  initializeKey,
  sortValues: customSortValues = {},
  filters: initialFilters = [],
  variants: initialVariants,
  hits: initialHits,
  page: initialPage = 1,
  displayMode,
  compactWithAttributes,
  showAllFilters,
  withFakeLink,
  showAll,
  withoutAvailabilitySwitchers,
  getPageChangeHref,
}: Props) => {
  const [isInitialized, { on }] = useBoolean(false);
  const [name, setName] = useState<string>('');
  const [selectedSort, setSelectedSort] = useState<SortId>(SortId.Popularity);
  const [showAvailable, { toggle: toggleShowAvailable, off: turnOffShowAvailable }] = useBoolean(false);
  const [showAvailableNow, { toggle: toggleShowAvailableNow }] = useBoolean(false);
  const [selectedFilters, setSelectedFilters] = useState<QueryFilter[]>([]);
  const [searchWithFilters, { data: searchWithFiltersData, loading }] = useFetch(searchWithFiltersRoute);

  const omittedFiltersKeys = map(
    initialFilters.filter((filter) => (filter.values as FilterValue[]).filter(({ count }) => count).length === 1),
    'key'
  );

  const queryFilters = [
    ...constantFilters,
    ...(showAvailableNow ? [{ key: 'availability_ts', values: ['24h'] }] : []),
    ...(showAvailable ? [{ key: 'is_available', values: ['1'], type: 'int' }] : []),
    ...selectedFilters,
  ];

  const searchParams = stringify(
    getSortedQueryParams(
      omit(
        queryFilters.reduce((acc, { key, values }) => ({ ...acc, [key]: values }), {} as Record<string, string[]>),
        ['is_available']
      )
    )
  );
  const href = `${routes.search.href}?${searchParams}`;

  const { data, limit, page, total, onLoadPage, onSetLimit, onReinitialize } = useList({
    initializeKey,
    data: initialVariants,
    hits: initialHits,
    page: initialPage,
    callback: async ({ limit: currentLimit, offset }) => {
      const { data: fetchData } = await searchWithFilters({
        name,
        mode: 'category',
        limit: showAll ? category.secretSizeLimit : currentLimit,
        offset,
        order: { ...sortValues, ...customSortValues }[selectedSort],
        filters: queryFilters,
      });

      return { data: fetchData?.search || [], hits: fetchData?.hits || 0 };
    },
  });

  const selectedFiltersCount = keysOf(selectedFilters).length;
  const selectedFilterValuesCount = selectedFilters.reduce((acc, curr) => acc + curr.values.length, 0);
  const constantFiltersKeys = [...map(constantFilters, 'key'), 'availability_ts', 'product'];

  const onFilterClick = (key: string, value: string) => {
    setSelectedFilters((currentFilters) => {
      const selectedFilter = currentFilters.find((currentFilter) => currentFilter.key === key);

      if (!selectedFilter) {
        return [...currentFilters, { key, values: [value] }];
      }

      const newValues = selectedFilter.values.includes(value)
        ? selectedFilter.values.filter((filterValue) => filterValue !== value)
        : [...selectedFilter.values, value];

      return newValues.length
        ? currentFilters.map((currentFilter) =>
            currentFilter.key === key ? { ...currentFilter, values: newValues } : currentFilter
          )
        : currentFilters.filter((currentFilter) => currentFilter.key !== key);
    });
  };

  const createFilters = (currentFilters: Filter[]) =>
    currentFilters.filter(
      (filter) =>
        !constantFiltersKeys.includes(filter.key) &&
        !omittedFiltersKeys.includes(filter.key) &&
        !filter.is_range &&
        (filter.values as FilterValue[]).filter(({ count }) => !!count).length
    );

  const [filters, setFilters] = useState<Filter[]>(createFilters(initialFilters));

  useEffect(() => {
    setFilters(createFilters(searchWithFiltersData?.filters || []));
  }, [searchWithFiltersData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setFilters(createFilters(initialFilters || []));
  }, [initializeKey]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(on); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isInitialized) {
      onReinitialize();
    }
  }, [name, showAvailable, selectedSort, showAvailableNow, selectedFilterValuesCount]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <VariantsListView
      className={className}
      productName={productName}
      searchName={name}
      href={href}
      selectedSort={selectedSort}
      loading={loading}
      data={data}
      limit={limit}
      page={page}
      total={total}
      filters={filters}
      withoutAvailabilitySwitchers={withoutAvailabilitySwitchers}
      compactWithAttributes={compactWithAttributes}
      showAvailable={showAvailable}
      showAvailableNow={showAvailableNow}
      selectedFilters={selectedFilters}
      selectedFiltersCount={selectedFiltersCount}
      showAllFilters={showAllFilters}
      withFakeLink={withFakeLink}
      showAll={showAll}
      displayMode={displayMode}
      getPageChangeHref={getPageChangeHref}
      onSearch={setName}
      onShowAvailableReset={turnOffShowAvailable}
      onShowAvailableClick={toggleShowAvailable}
      onShowAvailableNowClick={toggleShowAvailableNow}
      onSetLimit={onSetLimit}
      onLoadPage={onLoadPage}
      onSortChange={setSelectedSort}
      onFilterClick={onFilterClick}
      onFiltersClear={() => setSelectedFilters([])}
    />
  );
};

export default VariantsList;
