import Typography from '@components/atoms/Typography';
import { FormattedMessage } from 'utils/intl';

import classes from './VariantsListEmptyState.module.scss';
import messages from './VariantsListEmptyState.messages';

type Props = {
  productName?: string;
  onReset: () => void;
};

const VariantsListEmptyState = ({ productName = '', onReset }: Props): JSX.Element => (
  <div className={classes.container}>
    <Typography variant="h2" renderAs="div" weight="700">
      <FormattedMessage {...messages.header} values={{ productName }} />
    </Typography>
    <Typography variant="h4" renderAs="div">
      <FormattedMessage
        {...messages.description}
        values={{
          clickHere: (
            <u className={classes.link} onClick={onReset}>
              <FormattedMessage {...messages.clickHere} />
            </u>
          ),
        }}
      />
    </Typography>
  </div>
);

export default VariantsListEmptyState;
