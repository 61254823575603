import classNames from 'classnames';

import ArrowIcon from 'assets/icons/arrow.svg';
import createStyleVariables from 'utils/createStyleVariables';

import classes from './ExpandArrow.module.scss';

type Props = {
  className?: string;
  isOpened: boolean;
  wrapperWidth?: string;
  iconWidth?: string;
};

const ExpandArrow = ({ className, isOpened, wrapperWidth = '10px', iconWidth = '6px' }: Props): JSX.Element => (
  <div
    style={createStyleVariables({
      wrapperWidth,
      iconWidth,
    })}
    className={classNames(classes.wrapper, className)}
  >
    <ArrowIcon className={classNames(classes.icon, { [classes.opened]: isOpened })} />
  </div>
);

export default ExpandArrow;
