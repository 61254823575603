import { useState, useEffect } from 'react';

import useBoolean from 'hooks/useBoolean';

type LoadMoreParams = {
  limit: number;
  offset: number;
};

type Props<T> = {
  initializeKey?: number;
  data?: T[];
  hits?: number;
  limit?: number;
  offset?: number;
  page?: number;
  callback: (params: LoadMoreParams) => Promise<{ data: T[]; hits: number }>;
};

type UseListReturnType<T> = LoadMoreParams & {
  data: T[];
  page: number;
  total: number;
  hasMore: boolean;
  onLoadMore: () => Promise<void>;
  onLoadPage: (page?: number) => Promise<void>;
  onSetLimit: (limit: number) => Promise<void>;
  onReinitialize: () => Promise<void>;
};

const useList = <T>({
  initializeKey,
  data: initialData = [],
  hits: initialHits = 0,
  page: initialPage = 1,
  limit = 20,
  offset = 0,
  callback,
}: Props<T>): UseListReturnType<T> => {
  const [hits, setHits] = useState(initialHits);
  const [hasMore, { setValue: setHasMore }] = useBoolean(initialHits > initialData.length);
  const [data, setData] = useState<T[]>(initialData);
  const [params, setParams] = useState<LoadMoreParams>({ limit, offset: (initialPage - 1) * limit });

  const handleChange = async (newParams: LoadMoreParams, mode: 'push' | 'replace') => {
    setParams(newParams);
    const { data: newData, hits: newHits } = await callback(newParams);
    setHits(newHits);
    setData((currentData) => (mode === 'push' ? [...currentData, ...newData] : newData));
  };

  useEffect(() => {
    setHasMore(initialHits > initialData.length);
    setData(initialData);
    setParams({ limit, offset: (initialPage - 1) * limit });
  }, [initializeKey]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setHasMore(hits > data.length);
  }, [data, hits]); // eslint-disable-line react-hooks/exhaustive-deps

  const page = (params.offset + params.limit) / params.limit;

  const onLoadMore = async () => handleChange({ ...params, offset: params.offset + params.limit }, 'push');

  const onLoadPage = async (newPage: number = 1) =>
    handleChange({ ...params, offset: params.limit * (newPage - 1) }, 'replace');

  const onSetLimit = async (newLimit: number) => handleChange({ limit: newLimit, offset }, 'replace');

  const onReinitialize = async () => handleChange({ limit, offset }, 'replace');

  return {
    ...params,
    data,
    page,
    total: hits ? Math.ceil(hits / params.limit) : 1,
    hasMore,
    onLoadMore,
    onLoadPage,
    onSetLimit,
    onReinitialize,
  };
};

export default useList;
