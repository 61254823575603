import { ReactNode } from 'react';
import classNames from 'classnames';

import classes from './CollapsibleHeader.module.scss';

type Props = {
  isOpened: boolean;
  className?: string;
  children: ReactNode;
  onClick: () => void;
};

const CollapsibleHeader = ({ isOpened, className, children, onClick }: Props): JSX.Element => (
  <div className={classNames(classes.triggerWrapper, className)} onClick={onClick}>
    <div className={classes.trigger}>{children}</div>
    <div className={classes.arrowWrapper}>
      <div className={classNames(classes.arrow, { [classes.arrowOpened]: isOpened })} />
    </div>
  </div>
);

export default CollapsibleHeader;
