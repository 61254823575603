import { useState } from 'react';
import classNames from 'classnames';

import config from 'config';
import { SortId } from 'consts/sort';
import BinIcon from 'assets/icons/bin.svg';
import ArrowBoldLong from 'assets/icons/arrow-bold-long.svg';
import ProductDisplayMode from 'types/ProductDisplayMode';
import { Filter, FilterValue, QueryFilter } from 'types/Filter';
import Url from 'types/Url';
import SearchItem from 'types/SearchItem';
import GlassLayer from '@templates/GlassLayer';
import Button from '@components/atoms/Button';
import Pagination from '@components/Pagination';
import Typography from '@components/atoms/Typography';
import Collapsible from '@components/Collapsible';
import SearchProductsList from '@components/SearchProductsList';
import VariantsListEmptyState from '@templates/VariantsListEmptyState';
import PaginationSizeSwitcher from '@components/PaginationSizeSwitcher';
import { FormattedMessage } from 'utils/intl';
import { scrollToAnchor, scrollTo } from 'utils/scroll';
import useBoolean from 'hooks/useBoolean';

import FilterGroup from './FilterGroup';
import FilterActionButtons from './FilterActionButtons';
import classes from './VariantsList.module.scss';
import messages from './VariantsList.messages';

type Props = {
  className?: string;
  productName?: string;
  searchName?: string;
  href: string;
  selectedSort: SortId;
  withoutAvailabilitySwitchers?: boolean;
  showAvailable: boolean;
  showAvailableNow: boolean;
  loading: boolean;
  data: SearchItem[];
  page: number;
  total: number;
  filters: Filter[];
  limit: number;
  selectedFilters: QueryFilter[];
  selectedFiltersCount: number;
  showAllFilters?: boolean;
  withFakeLink?: boolean;
  showAll?: boolean;
  compactWithAttributes?: boolean;
  displayMode?: ProductDisplayMode;
  getPageChangeHref?: (page: number | undefined) => Url;
  onSearch: (value: string) => void;
  onShowAvailableClick: () => void;
  onShowAvailableReset: () => void;
  onShowAvailableNowClick: () => void;
  onLoadPage: (page?: number) => void;
  onSetLimit: (limit: number) => void;
  onSortChange: (id: SortId) => void;
  onFilterClick: (key: string, value: string) => void;
  onFiltersClear: () => void;
};

const VariantsList = ({
  className,
  productName,
  searchName,
  selectedSort,
  href,
  loading,
  data,
  filters,
  limit,
  page,
  total,
  withoutAvailabilitySwitchers,
  showAvailable,
  showAvailableNow,
  selectedFilters,
  selectedFiltersCount,
  showAllFilters: initialShowAllFilters = false,
  displayMode: initialDisplayMode = 'compact',
  compactWithAttributes,
  withFakeLink,
  showAll,
  getPageChangeHref,
  onSearch,
  onShowAvailableClick,
  onShowAvailableReset,
  onShowAvailableNowClick,
  onSetLimit,
  onLoadPage,
  onSortChange,
  onFilterClick,
  onFiltersClear,
}: Props) => {
  const [displayMode, setDisplayMode] = useState<ProductDisplayMode>(initialDisplayMode);
  const [showAllFilters, { toggle }] = useBoolean(initialShowAllFilters);

  const mapFilter = (filter: Filter) => {
    const filterValues = (filter.values as FilterValue[])
      .filter(({ count }) => !!count)
      .map(({ name, count }) => ({ key: name, name, count }));
    const isSelected = (value: string) =>
      !!selectedFilters.find((selectedFilter) => selectedFilter.key === filter.key)?.values.includes(value);
    return (
      <FilterGroup
        key={filter.key}
        name={filter.name}
        values={filterValues}
        isSelected={isSelected}
        onClick={(value) => onFilterClick(filter.key, value)}
      />
    );
  };

  const onReset = () => {
    onShowAvailableReset();
    onFiltersClear();
    onSearch('');
  };

  return (
    <div className={classes.container}>
      {loading && <GlassLayer position="sticky" placement="top" />}
      <div className={classNames(classes.filtersContainer, classes.filters)}>
        <Collapsible
          isOpened={showAllFilters}
          className={classes.filtersCollapse}
          trigger={() => (
            <FilterActionButtons
              searchName={searchName}
              selectedSort={selectedSort}
              displayMode={displayMode}
              withoutAvailabilitySwitchers={withoutAvailabilitySwitchers}
              showAllFilters={showAllFilters}
              showAvailable={showAvailable}
              showAvailableNow={showAvailableNow}
              onToggle={toggle}
              onDisplayModeChange={setDisplayMode}
              onSearch={onSearch}
              onSortChange={onSortChange}
              onShowAvailableClick={onShowAvailableClick}
              onShowAvailableNowClick={onShowAvailableNowClick}
            />
          )}
          accordion
        >
          <div className={classes.filtersBox} />
          <div className={classes.filtersContent}>
            {filters.map(mapFilter)}
            <div className={classes.filtersButtons}>
              <Button href={href} className={classes.filterButton} color="tertiary" component="span">
                <FormattedMessage {...messages.goToFullSearch} />
                <ArrowBoldLong />
              </Button>
              {!!selectedFiltersCount && (
                <Button color="secondary" variant="text" className={classes.filterButton} onClick={onFiltersClear}>
                  <Typography variant="h4" renderAs="span" weight="700">
                    <FormattedMessage {...messages.clearFilters} values={{ count: selectedFiltersCount }} />
                  </Typography>
                  <BinIcon width={20} />
                </Button>
              )}
            </div>
          </div>
        </Collapsible>
      </div>
      {data.length ? (
        <SearchProductsList
          data={data}
          displayMode={displayMode}
          className={className}
          withFakeLink={withFakeLink}
          compactWithAttributes={compactWithAttributes}
          scroll
        />
      ) : (
        <VariantsListEmptyState productName={productName} onReset={onReset} />
      )}
      {!showAll && getPageChangeHref && (
        <div className={classes.pagination} data-nosnippet>
          <PaginationSizeSwitcher
            numbers={config.category.paginationSizeList}
            selected={limit}
            onChange={(newLimit) => {
              onSetLimit(newLimit);
              if (newLimit < limit) {
                scrollToAnchor(`product-${newLimit - 4}`);
              }
            }}
          />
          <Pagination
            current={page}
            total={total}
            getPageChangeHref={getPageChangeHref}
            onClick={(newPage) => {
              onLoadPage(newPage);
              scrollTo(`.${classes.container}`);
            }}
            withFakeLink={withFakeLink}
          />
        </div>
      )}
    </div>
  );
};

export default VariantsList;
