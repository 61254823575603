import classNames from 'classnames';

import { SortId } from 'consts/sort';
import ProductDisplayMode from 'types/ProductDisplayMode';
import ExpandArrow from '@components/atoms/ExpandArrow';
import Typography from '@components/atoms/Typography';
import ShowAvailable from '@components/ShowAvailable';
import SortProducts from '@components/SortProducts';
import SearchBox from '@components/SearchBox';
import ListVariantSwitcher from '@components/ListVariantSwitcher';
import Button from '@components/atoms/Button';
import { FormattedMessage } from 'utils/intl';

import classes from './FilterActionButtons.module.scss';
import messages from './FilterActionButtons.messages';

type Props = {
  searchName?: string;
  displayMode: ProductDisplayMode;
  showAvailable: boolean;
  showAvailableNow: boolean;
  showAllFilters: boolean;
  selectedSort: SortId;
  withoutAvailabilitySwitchers?: boolean;
  onSearch: (value: string) => void;
  onToggle: () => void;
  onShowAvailableClick: () => void;
  onShowAvailableNowClick: () => void;
  onSortChange: (id: SortId) => void;
  onDisplayModeChange: (name: ProductDisplayMode) => void;
};

const FilterActionButtons = ({
  searchName,
  displayMode,
  showAvailable,
  showAvailableNow,
  showAllFilters,
  selectedSort,
  withoutAvailabilitySwitchers,
  onSearch,
  onShowAvailableClick,
  onShowAvailableNowClick,
  onDisplayModeChange,
  onToggle,
  onSortChange,
}: Props): JSX.Element => (
  <div className={classes.buttons}>
    <div className={classes.buttonsSection}>
      <Button
        color="tertiary"
        className={classNames(classes.showMoreFiltersButton, { [classes.active]: showAllFilters })}
        onClick={onToggle}
      >
        <Typography className={classes.button} variant="h4" renderAs="div" weight="700">
          <FormattedMessage {...(showAllFilters ? messages.showLessFilters : messages.showMoreFilters)} />
          <ExpandArrow className={classes.expandArrow} isOpened={showAllFilters} />
        </Typography>
      </Button>
    </div>
    <div className={classes.additionalFilters}>
      <div className={classes.row}>
        <div className={classes.sort}>
          <SortProducts selectedItemId={selectedSort} onChangeActive={onSortChange} />
        </div>
        <SearchBox
          classes={{ root: classes.search }}
          searchName={searchName}
          onSearch={onSearch}
          onSubmit={() => {}}
          withAutofocus={false}
        />
      </div>
      <div className={classes.switchers}>
        {!withoutAvailabilitySwitchers && (
          <>
            <div className={classes.buttonsSection}>
              <ShowAvailable
                isAvailable={showAvailableNow}
                messages={{
                  full: <FormattedMessage {...messages.availableNow} />,
                  compact: <FormattedMessage {...messages.availableNow} />,
                }}
                onClick={onShowAvailableNowClick}
              />
              <ShowAvailable isAvailable={showAvailable} onClick={onShowAvailableClick} />
            </div>
            <hr className={classes.divider} />
          </>
        )}
        <ListVariantSwitcher displayMode={displayMode} onDisplayModeChange={onDisplayModeChange} />
      </div>
    </div>
  </div>
);

export default FilterActionButtons;
