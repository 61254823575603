import { ReactNode, useRef, useState } from 'react';
import classNames from 'classnames';

import { NOSCRIPT_CLASSES } from 'core/noscript.styles';
import Typography from '@components/atoms/Typography';
import CollapsibleHeader from '@templates/CollapsibleHeader';
import useElementHeight from 'hooks/useElementHeight';
import Message from 'types/Message';

import createStyleVariables from 'utils/createStyleVariables';
import classes from './Collapsible.module.scss';

type TwoStateTrigger = { opened: Message; closed: Message };

type Props = {
  initiallyOpen?: boolean;
  className?: string;
  children: ReactNode | ((isOpen: boolean) => ReactNode);
  trigger: Message | ((isOpen: boolean, onClick: () => void) => ReactNode) | TwoStateTrigger;
  unmountOnClose?: boolean;
} & (
  | {
      accordion: true;
      isOpened: boolean;
      onClick?: () => void;
    }
  | {
      accordion?: false;
      isOpened?: never;
      onClick?: never;
    }
);

const isTwoStateTrigger = (trigger: Props['trigger']): trigger is TwoStateTrigger =>
  !!((trigger as TwoStateTrigger).closed && (trigger as TwoStateTrigger).opened);

const Collapsible = ({
  className,
  children,
  initiallyOpen = false,
  trigger,
  accordion = false,
  isOpened,
  unmountOnClose,
  onClick,
}: Props): JSX.Element => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(initiallyOpen);
  const collapseHeight = useElementHeight(contentRef);
  const collapsibleHeight = collapseHeight === 'auto' ? 'auto' : `${collapseHeight}px`;
  const isActuallyOpened = accordion ? !!isOpened : isOpen;
  const toggle = () => setIsOpen((prevState) => !prevState);
  const handleClick = () => {
    if (accordion && typeof onClick === 'function') {
      onClick();
    } else {
      toggle();
    }
  };

  return (
    <div
      style={createStyleVariables({
        collapsibleHeight: isActuallyOpened ? collapsibleHeight : 0,
      })}
      className={classNames(classes.wrapper, className)}
    >
      {typeof trigger === 'function' ? (
        trigger(isActuallyOpened, handleClick)
      ) : (
        <CollapsibleHeader isOpened={isActuallyOpened} onClick={handleClick}>
          <Typography variant="h4" renderAs="span">
            {!isTwoStateTrigger(trigger) ? trigger : <>{isOpen ? trigger.opened : trigger.closed}</>}
          </Typography>
        </CollapsibleHeader>
      )}
      <div className={classNames(classes.contentWrapper, NOSCRIPT_CLASSES.COLLAPSIBLE_CONTENT)}>
        <div ref={contentRef}>
          {(!unmountOnClose || isActuallyOpened) &&
            (typeof children === 'function' ? children(isActuallyOpened) : children)}
        </div>
      </div>
    </div>
  );
};

export default Collapsible;
